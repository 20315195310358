.layout{
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    height: 100vh;
    overflow-x: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    background: #F6F7F9;
    
    
    @media (max-width:1100px){
        margin-left: auto;
        margin-right: auto;
        padding-left: 20px;
        padding-right: 20px;
        overflow-x: hidden;
    }
}
.header{
    width: 100%;
    margin: 0 auto;
    margin-left: 85px;
    margin-top: 20px;
    @media (max-width:580px){
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 30px;
    }
    // @media (max-width:280px){
    //     margin-left: 85px;
    // }
}
.main{
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; 
    flex-grow: 1;
    
  
}

.box{
    width: 40%;
    max-width: 583px;
    position: relative;
    margin-top: 15px ;
    border-radius: 10px;
    border: 1px solid var(--Card-stroke, #E7EAEE);
    background: #FFF;
    padding: 20px 30px;

    @media (max-width:800px){
        min-width: 500px;
        
    }
    @media (max-width:600px){
        margin-top: 32px;
        min-width: 350px;
    }
    @media (max-width:400px){
       
        min-width: 300px;
    }
    @media (max-width:310px){
       
        min-width: 280px;
    }

    
    
}
.top_title{
    display: flex;
    align-items: center;
    gap:132px;

    @media (max-width:1230px){
        gap: 80px;
    }
    @media (max-width:419px){
        gap: 50px;
    }
    @media (max-width:311px){
        gap: 30px;
    }
    
}
.title{
    color: var(--grey-900, #101928);
    text-align: center;
    font-feature-settings: 'cv04' on, 'cv03' on, 'cv01' on;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: -0.48px;
    
}
.space{
    margin: 0 auto;
}
.balance{
    border-radius: 12px;
    background: #F7FCFC;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 22px;
    padding-bottom: 22px;
    margin-top: 15px;
    .balance_amount{
        color: var(--flick-secondary-5, #151F32);
        font-family: Circular Std;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%; /* 20px */
        letter-spacing: -0.5px;
    }
    .amount-content{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        margin-top: 11px;

        .dolla_amount{
            border-radius: 16px;
            border: 1px solid var(--success-200, #ABEFC6);
            background: var(--success-50, #ECFDF3);
            mix-blend-mode: multiply;
            padding: 2px 8px 2px 6px;
            display: flex;
            padding: 2px 8px 2px 6px;
            align-items: center;
            gap: 4px;
            color: var(--success-700, #067647);
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px; /* 180% */
        }
    }
}

.securedBtn{
    padding: 12px 24px;
    margin-top: 15px;
    border-radius: 4px;
    display:flex;
    gap: 8px;
    //  border: 1px solid #E6E6E6;
    // background: #FFF;
    cursor: pointer;
}
.secured_content{
    display: flex;
    gap: 4px;
    align-items: center;
}
.secured_p{
    color: #000;
    font-family: Circular Std;
    font-size: 14px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
    letter-spacing: -0.35px;
}

.footer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 10px;

    .helpBtn{
        border-radius: 12px;
        width: 50.8px;
        height: 32px;
        background: #F6F8FA;
        color: var(--flick-secondary-5, #151F32);
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; 
        display: block;
    }

    .footer_menu{
        display: flex;
        gap: 15px;
        align-items: center;
        & span{
            color: var(--Flick-Subtext, #8C8F97);
            font-family: Circular Std;
            font-size: 13px;
            font-style: normal;
            font-weight: 300;
            line-height: 20px; /* 153.846% */
        }
    }
}



.layout_success{
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    height: 100vh;   
    position: relative;

    
}

.main_success{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.box_center{
    width: 40%;
    max-width: 583px;
    border-radius: 10px;
    border: 1px solid var(--Card-stroke, #E7EAEE);
    background: #FFF;
    padding: 20px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; 
    @media (max-width:1000px){
        min-width: 350px;
    }
    @media (max-width:350px){
        min-width: 300px;
    }
}

.success_img{
  margin-top: auto;  
}
.success_title{
    color: var(--grey-900, #101928);
    text-align: center;
    font-feature-settings: 'cv04' on, 'cv03' on, 'cv01' on;
    font-family: Circular Std;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    margin-top: 24px;
}
.success_desc{
    color: var(--Flick-Subtext, #8C8F97);
    text-align: center;
    font-family: Circular Std;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: -0.35px;
    margin-bottom: 32px;
}
.dashboard{
    width: 100%;
    max-width: 1440px;
    background: #F6F7F9;
    margin: 0 auto;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
   
    // justify-content: center;
    .dashboard_container{
        margin-top: 15vh;
        border-radius: 10px;
        background: #FFF;
        max-width: 395px;
        width: 395px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 21px;

        .dashboard_log{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .dashboard_desc{
                color:  #8C8F97;
                text-align: center;
                font-size: 12px;
                font-style: normal;
                font-weight: 450;
                line-height: normal;
                letter-spacing: -0.24px;
            }

        }
    }
}

.inputs_group{
    border-radius: 8px;
    border: 1px solid var(--Flick-Stroke, #EAECF0);
    background: #FFF;
    width: 100%;
    padding: 15px 10px ;
    display: block;
    &:focus{
        outline: none;
        border:1px solid #2EBDB6 !important;
    }
    &.halved{
        width: 48%;
    }
    &.fixed_amount{
        background: #F9FAFB;
        &:focus{
            outline: none;
            border:  1px solid var(--Flick-Stroke, #EAECF0);
        }
    }
}
.inputs_container{
    display: flex;
    justify-content: space-between;
}
.form_group{
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.charge_amount{
    display: flex;
    padding: 15px 10px ;
    border: 1px solid var(--gray-200, #EAECF0);
    border-radius: 8px;

    .charge_amount_select{
        border: none;
        &:focus{
            outline: none;
        }
    }

}
.charge_amout_container{
    display: flex;
    gap: 10px;
}

.inputs_group{
    &:focus{
        border:1px solid #2EBDB6 !important;
    }
}
.focuse{
    &:focus{
        border:1px solid #2EBDB6 !important;
    }
}
.title_transfer{
    width: 70%;
    text-align: center;
    font-weight: 700;
    text-transform: capitalize;
}