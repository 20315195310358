.bank{
    width: 100%;
}
.bank_select{
    margin-top: 16px;
    margin-bottom: 16px;
}
.bank_title{
    color: var(--grey-900, #101928);
    font-feature-settings: 'cv04' on, 'cv03' on, 'cv01' on;
    font-family: Circular Std;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 171.429% */
    margin-top: 16px;
}
.bank_container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.bank_logo{
    width: 66px;
    height: 69px;
    flex-shrink: 0;
    border-radius: 50%;
    margin-top: 11px;
    margin-left: auto;
    margin-right: auto;
}
.bank_payment{
    color: var(--grey-900, #101928);
    text-align: center;
    font-feature-settings: 'cv04' on, 'cv03' on, 'cv01' on;
    font-family: Circular Std;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px; /* 200% */
    letter-spacing: -0.32px;
    margin-left:auto ;
    margin-right: auto;
    margin-top: 21px;
}
.bank_form{
    width: 100%;
}
.bank_account_id{
    border-radius: 8px;
    border: 1px solid var(--Flick-Stroke, #EAECF0);
    background: #FFF;
    display: flex;
    padding: 12px 10px 13px 10px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 100%;
    margin-top: 16px;
    &:focus{
        outline: none;
    }
}
.input_container{
    position: relative;
    padding-top: 15px;
    margin-top: 14px;
    margin-bottom: 24px;
    .input_group{
        border-radius: 8px;
       
        background: #FFF;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid var(--Flick-Stroke, #EAECF0);
        background: #FFF;
        padding: 8px 16px;
        
        .input_cardNumber{
            border: none;
            width: 80%;
            padding-top: 6px;
            padding-bottom: 6px;
            margin-top: 3px;
            &::placeholder {
                
                text-align: start;
              }
            &:focus{
                
                outline: none;
            }

        }
    }
    .card_label{
        position: absolute;
        top: 0;
        left: 6px;
        color: var(--flick-primary-5, #2EBDB6);
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Circular Std;
        font-size: 12px;
        font-style: normal;
        font-weight: 450;
        line-height: 20px; /* 166.667% */
        background: #FFF;
        padding: 4px;
    }

}

// bank selection

.bank_select_title{
    color: var(--grey-900, #101928);
    font-feature-settings: 'cv04' on, 'cv03' on, 'cv01' on;
    font-family: Circular Std;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 171.429% */
    margin-top: 16px;
}
.bank_accounts_option{
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 11px;
  margin-top: 16px;
}

.bank_item{
    border-radius: 8px;
    background: #F7FCFC;
    padding: 10px 10px 10px 17px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: flex-start;
    

    &.active{
        border: 1px solid var(--flick-primary-5, #2EBDB6);
    }

}

.bank_item_left{
    display: flex;
    gap: 17px;
    align-items: flex-start;

    .bank_item_content{
        display: flex;
        flex-direction: column;
        
        .bank_item_name{
            color: var(--Flick-Subtext, #8C8F97);
            font-family: Circular Std;
            font-size: 12px;
            font-style: normal;
            font-weight: 450;
            line-height: 18px; /* 150% */
            margin-bottom:10px;
            .bank_item_acc_num{
                color: #8C8F97;
                font-family: Circular Std;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 18px;
            }
        }

        .bank_item_amount{
            color: var(--gray-700, #344054);
            font-family: Circular Std;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px; /* 142.857% */
        }
    }
}