@import url('https://fonts.cdnfonts.com/css/circular-std');

/*-------------------------
    Variables
-------------------------*/
:root {
  --color-primary: #259792;
  --color-white: #ffffff;
  --color-black: #000000;
  --color-light: #FAFCFE;
  --color-dark: #8C8F97;
  --color-primary-link: #1f5a57;
  --color-dark-link: #7f8082;
  --color-link: #7f8082;

  --transition: all 0.3s ease-in-out;

}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

@keyframes dropTop {
  0% {
    transform: translateY(-100%) scale(0);
    opacity: 0;
  }

  100% {
    transform: translateY(0%) scale(1);
    opacity: 1;
  }
}

@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

a {
  display: inline-block;
  text-decoration: none;
  transition: var(--transition);
  color: #2EBDB6;
}

span {
  display: inline-block;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  display: block;
}


ul {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}


body {
  margin: 0;
 
  font-family: 'Circular Std', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 50%, 0);
    transform: translate3d(0, 50%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 50%, 0);
    transform: translate3d(0, 50%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

/*  */
@-webkit-keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(.3, .3, .3);
    transform: scale3d(.3, .3, .3);
  }

  50% {
    opacity: 1;
  }
}

@keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(.3, .3, .3);
    transform: scale3d(.3, .3, .3);
  }

  50% {
    opacity: 1;
  }
}

.zoomIn {
  -webkit-animation-name: zoomIn;
  animation-name: zoomIn;
}

@-webkit-keyframes flash {

  from,
  50%,
  to {
    opacity: 1;
  }

  25%,
  75% {
    opacity: 0;
  }
}

@keyframes flash {

  from,
  50%,
  to {
    opacity: 1;
  }

  25%,
  75% {
    opacity: 0;
  }
}

.flash {
  -webkit-animation-name: flash;
  animation-name: flash;
}

.copyInput {
  outline: none;
  background: transparent;
  border: none;
  width: 95px;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button,
input[type=number]:focus {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;

}

/*-------------------------
    Button
-------------------------*/

.btn {
  width: 100%;
  padding: 12px;
  background: var(--color-light);
  border-radius: 6px;
  font-style: normal;

  height: auto;
  text-align: center;
  transition: all 0.3s ease-in-out;
  position: relative;
  z-index: 1;
  background-color: #151F32;
  border-radius: 6px;
  background: #2EBDB6;
  color: #F7F8FA;
  overflow: hidden;
  border: none;
  outline: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;


}

.sec-btn {
  width: 100%;
  height: auto;
  text-align: center;
  padding: 14px;
  border-radius: 6px;
  outline: none;
  overflow: hidden;
  border: 1px solid var(--Flick-Stroke, #EAECF0);
  background-color: white;
  color: var(--flick-secondary-5, #151F32);
  font-size: 14px;
  font-weight: 450;
  line-height: normal;
}





.btn::after,
.btn-md::after,
.btn-light::after {
  content: "";
  height: 300px;
  width: 300px;
  background-color: #0d5653;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%) scale(0);
  transition: 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  z-index: -1;

}


.btn:hover {
  background-color: #0d5653;
  border-color: #0d5653;
  color: #F7F8FA;
}

.btn-white:hover {
  background-color: #fefdfd;
  border-color: #a1a3a7;
}

.btn:hover::after,
.btn-white:hover::after,
.btn-md:hover::after,
.btn-landing::after,
.btn-light:hover::after {
  transform: translateY(-50%) translateX(-50%) scale(1);
}

/* input group */
/* input::placeholder {
  color: #D0D5DD !important;
} */


.text_h1 {
  color: var(--flick-secondary-5, #151F32);
  font-family: Circular Std;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  /* 32px */
  letter-spacing: -0.8px;
}

.text_h2 {
  color: var(--grey-900, #101928);
  text-align: center;
  font-feature-settings: 'cv04' on, 'cv03' on, 'cv01' on;
  font-family: Circular Std;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: -0.48px;
}

.text_h3 {
  color: var(--grey-900, #101928);
  font-feature-settings: 'cv04' on, 'cv03' on, 'cv01' on;
  font-family: Circular Std;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.text_p1 {
  color: var(--gray-600, #475467);
  font-family: Circular Std;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
  /* 166.667% */
}

.text_p2 {
  color: var(--Flick-Subtext, #8C8F97);
  font-family: Circular Std;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: -0.35px;
}