.bank_transfer {
  width: 100%;
  margin-top: 15px;
  
  .bank_transfer_content_empty{
    width: 100%;
    padding: 22px 15px;
    border-radius: 6px;
    background: #f7fcfc;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .bank_transfer_content {
    width: 100%;
    padding: 22px 15px;
    border-radius: 6px;
    background: #f7fcfc;
    height: 140px;
    position: relative;
    .bank_row {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .bank_light {
        color: var(--gray-600, #475467);
        font-family: Circular Std;
        font-size: 12px;
        font-style: normal;
        font-weight: 300;
        line-height: 20px; /* 166.667% */
      }
    }
  }
}
.copy_div {
  display: flex;
  width: 80px;
}
.copyInput {
  border: none;
  background: transparent;
}
.copyInput_width{
  width: 80px;

}
.copyInput_widthTest{
  width: 80px;
  color: #067647;
}
.bank_thick {
  color: var(--flick-secondary-5, #151f32);
  font-family: Circular Std;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}
.space {
  margin-top: 21px;
}
.bank_transfer_content {
  display: flex;
  flex-direction: column;
}
.bank_time {
  color: var(--Flick-Subtext, #8c8f97);
  text-align: center;
  font-family: Circular Std;
  font-size: 12px;
  font-style: normal;
  font-weight: 450;
  line-height: 18px;
  margin-bottom: 16px;
}

.btn_bank {
  border-radius: 6px;
  border: 1px solid var(--Flick-Stroke, #eaecf0);
  display: flex;
  width: 443px;
  padding: 14px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
//Confirm Transfer
.confirm-transfer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin-top: 16px;
  .confirm {
    color: var(--grey-900, #101928);
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    padding-left: 100px;
    padding-right: 100px;
    
    @media (max-width:1100px){
      padding-left: 0;
      padding-right: 0;
    }
  }
  .loading {
    width: 224px;
    height: 178px;
  }
  .show-account {
    color: var(--Flick-Subtext, #8c8f97);
    font-size: 14px;
    font-weight: 450;
    line-height: normal;
    cursor: pointer;
  }
  .contact {
    color: var(--grey-900, #101928);
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
  }
}

.lottie-size{
  width: 198.5px;
 height: 157.737px;
}

.displayRow{
  display: flex;
  justify-content: space-between;
  margin-top: 21px;
}
.bank_transfer_title_container{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 16px;
}
.transfer_bank_select{
  display: flex;
  border-radius: 6px;
  background: #F7FCFC;
  padding: 6px 8px;
  gap: 10px;
  align-items: center;

  
 
}
.mySelect{
  border: none;
  background: transparent;
  &:focus{
    outline: none;
  
  }
}

.bank_transfer_title {
    color: var(--grey-900, #101928);
    font-feature-settings: "cv04" on, "cv03" on, "cv01" on;
    font-family: Circular Std;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    
 }

 