.input_wrapper{
    width: 100%;
    margin-top: 28px;
}
.input-container {
    display: flex;
    width: 100%;
    
    align-items: center;
    padding: 10px;
    cursor: pointer;
    border-radius: 8px;
    
    background: var(--base-white, #fff);
    border-radius: 8px;
    border: 1px solid var(--Flick-Stroke, #EAECF0);
    background: #FFF;
    .bank-name {
      color: var(--gray-900, #101828);
      font-size: 14px;
      font-weight: 450;
      line-height: 24px;
      margin-left: 8px;
    }
}


.bank-list {
    position: relative;
    display: flex;
    align-items: flex-start;
    .modal-container {
      position: absolute;
      width: 100%;
      height: 180px;
      overflow-y: auto;
      z-index: 5;
      border-radius: 8px;
      border: 1px solid var(--gray-200, #eaecf0);
      background: var(--base-white, #fff);
      margin-top: 8px;
      box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03),
        0px 12px 16px -4px rgba(16, 24, 40, 0.08);
    }
  

  
    .icon-bank {
      display: flex;
      align-items: center;
      padding: 10px 8px;
      cursor: pointer;
      &.active{
        background-color: #F9FAFB;
      }
      .bank-name-icon {
        display: flex;
        justify-content: space-between;
        width: 100%;

        .bank-name {
          color: var(--gray-900, #101828);
          font-size: 14px;
          font-weight: 450;
          line-height: 24px;
          margin-left: 8px;
        }
      }
    }
  }
  .option_title{
    color: var(--grey-900, #101928);
    font-feature-settings: 'cv04' on, 'cv03' on, 'cv01' on;
    font-family: Circular Std;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; 
     margin-bottom: 4px;
  }
  .option_desc{
    color: var(--Flick-Subtext, #8C8F97);
    font-family: Circular Std;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: -0.35px;
    margin-bottom: 12px;
  }

  .rule{
    width: 100%;
    margin-top: 24px;
    height: 1px;
    background: #E4E7EC;
    margin-top: 24px;
  }


  .container_overlay{
    width:100%;
    position: relative;
    background:red;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  
  
  
    
  }
  .loading_overlay{
    width: 100%;
    height: 100%;
    position: absolute;
    
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    background:rgb(46, 189, 182,);
  
   
  }
  .loader_icon{
    margin: auto;
  }