.card_container{
    width: 100%;

    .card_h{
        color: var(--grey-900, #101928);
        font-feature-settings: 'cv04' on, 'cv03' on, 'cv01' on;
        font-family: Circular Std;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 150% */
        margin-bottom: 16px;
        margin-top: 24px;
    }
}

.card_name{
    padding: 14px 16px;
    width: 100%;
    border-radius: 6px;
    border: 1px solid var(--Flick-Stroke, #EAECF0);
    background: var(--shade-white, #FFF);
    &:focus{
        outline: none;
        border: 1px solid #2EBDB6;
    }
}
.input_container{
    position: relative;
    padding-top: 18px;
    .input_group{
        border-radius: 8px;
        background: #FFF;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #FFF;
        padding: 8px 16px;
        border: 1px solid var(--Flick-Stroke, #EAECF0);
        &.clicked{
            border: 1px solid #2EBDB6;
        }
        .input_cardNumber{
            border: none;
            width: 80%;
            &:focus{
                outline: none;
            }

        }
    }
    .card_label{
        position: absolute;
        top: 0;
        left: 6px;
        color: var(--flick-primary-5, #2EBDB6);
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Circular Std;
        font-size: 12px;
        font-style: normal;
        font-weight: 450;
        line-height: 20px; /* 166.667% */
        background:transparent;
        border: none;
        padding: 4px;
    }

}

.card_data_container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 16px;
    margin-bottom: 16px;
    .card_date{
       width: 48%; 
       border-radius: 6px;
       border: 1px solid var(--grey-300, #D0D5DD);
        background: var(--shade-white, #FFF);
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 8px;
        &.clicked{
            border: 1px solid #2EBDB6;
        }
       .card_date_input{
        width: 90%;
        color: var(--grey-700, #344054);
        font-feature-settings: 'cv04' on, 'cv03' on;
        font-family: Circular Std;
        font-size: 12px;
        font-style: normal;
        font-weight: 450;
        line-height: 20px; /* 166.667% */
        border: none;
        &:focus{
            outline: none;
        }
       }
    }
}

.card-details{
    margin-top: 16px;
    .card_h{
        color: var(--grey-900, #101928);
        font-feature-settings: 'cv04' on, 'cv03' on, 'cv01' on;
        font-family: Circular Std;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 171.429% */
    }
    .card_p{
        color: var(--Flick-Subtext, #8C8F97);
        font-family: Circular Std;
        font-size: 12px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        letter-spacing: -0.3px;
    }
}
.card_pin_form{
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.card_pin_container{
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 16px;

    .pin{
        border-radius: 8px;
        border: 1px solid var(--Flick-Stroke, #EAECF0);
        background: var(--base-white, #FFF);
        padding: 2px 8px;
        color: var(--flick-secondary-5, #151F32);
        text-align: center;
        font-family: Circular Std;
        font-size: 48px;
        font-style: normal;
        font-weight: 450;
        line-height: 60px; /* 125% */
        letter-spacing: -0.96px;
        width: 23%;
        &:focus{
            outline: 1px solid var(--flick-primary-5, #2EBDB6);
        }
    }
}
.otp_resend{
    color:  #2EBDB6;
    font-size: 12px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
    letter-spacing: -0.3px;
    cursor: pointer;
}

// overlay modals


.verify_modal{
    position: fixed;
    background: rgba(0, 0, 0, 0.20);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .verify_modal_content{
        position: relative;
        background: white;
        border-radius: 8px;
        width: 34.6%;
        max-width: 498px;
        padding: 32px 0px;
        animation: fadeInRight .3s linear;
        display: flex;
        align-items: center;
        justify-content: center;
        .verify_modal_wrapper{
            width: 87%;
            
            .verify-close{
                margin-left: auto;
            }
            .verify_identity_title{
                margin-top: 10px;
                margin-bottom: 18px;
                padding-bottom: 16px;
                border-bottom: 1px solid var(--flick-stroke, #EAECF0);

                & .verify_identity_h{
                    color: var(--gray-900, #101828);
                    font-family: Circular Std;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }

                & .verify_identity_p{
                    color: var(--flick-subtext, #8C8F97);
                    font-family: Circular Std;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 450;
                    line-height: normal;
                }
            }
            .verify_identity_form{
                display: flex;
                flex-direction: column;
                gap: 20px;

                .form-group{
                    & span{
                        color:  #344054;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 20px; 
                    }
                    .input-control{
                        width: 100%;
                        border-radius: 8px;
                        border: 1px solid  #D0D5DD;
                        background: #FFF;
                        padding: 8px 12px;
                        outline: none;
                    }
                }
            }
        }
    }
}
.input_selection{
    width: 100%;
    border-radius: 8px;
    background: #F7FCFC;
    padding: 22px;
    .input_selection_details{
        text-align: center;
        color:  #475467;
        font-size: 12px;
        font-style: italic;
        font-weight: 450;
        line-height: 20px; 

        & span{
            color:  #ED1C24;
        }
    }
}

.modal_containers{
    width: 43%;
    max-width: 480px;
    background: #fff;
    animation: fadeInRight .3s linear;
    position: fixed;
    padding:24px;
    border-radius: 20px;

    .modal_top{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .modal_top_left{
            display: flex;
            gap: 8px;
            .naira_modal{
                color: var(--flick-secondary-5, #151F32);
                font-family: Circular Std;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }
        }
    }
    .modal_aval_bal{
        color:  #475467;
        font-family: Circular Std;
        font-size: 12px;
        font-style: normal;
        font-weight: 450;
        line-height: 20px; 
        cursor: pointer;

        &  span{
            color: var(--gray-600, #475467);
            font-family: Circular Std;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px;

            &.lin_through{
                text-decoration: line-through;
            }
        }
    }

    .modal_aval_items{
        margin-top: 29px;

        .modal_aval_balance{
            display: flex;
            gap: 16px;
            align-items: center;

            .modal_fund_balance{
                .modal_fund_title{
                    color: var(--flick-secondary-5, #151F32);
                    font-family: Circular Std;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    letter-spacing: -0.28px;
                    margin-bottom: 5px;
                }
                .modal_fund_desc{
                    color: var(--gray-600, #475467);
                    font-family: Circular Std;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 20px; /* 142.857% */
                }
            }
        }
    }
}
.bg_line{
    width: 263px;
    background: #EAECF0;
    height: 1px;
    margin-top: 14px;
    margin-bottom: 22px;
}
.amount_limits{
    border-radius: 16px;
    border: 1px solid var(--success-200, #ABEFC6);
    background: var(--success-50, #ECFDF3);
    mix-blend-mode: multiply;
    padding: 2px 8px 2px 6px;
    color: var(--success-700, #067647);
    text-align: center;
    font-family: Circular Std;
    font-size: 8px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; 
    margin-left: 14px;/* 225% */
    height: 18px;
    display: inline;
    display: flex;
    align-items: center;
    gap: 4px;
    width: fit-content;
}
.mod_flex{
    display: flex;
}

