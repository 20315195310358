// landing

.page {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  height: 100vh;
  overflow: hidden;
}
.success-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  .success {
    color: var(--grey-900, #101928);
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    margin-top: 24px;
    margin-bottom: 4px;
  }
  .flick-test {
    color: var(--Flick-Subtext, #8c8f97);
    text-align: center;
    font-size: 14px;
    font-weight: 300;
    line-height: normal;
    letter-spacing: -0.35px;
    margin-bottom: 32px;
  }
}
